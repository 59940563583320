// src/router/MainRouter.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import GarmentDashboard from "../screens/admin/analytics/GarmentDashboard";
import HoursWorkedPivotTable from "../screens/admin/analytics/HoursWorkedPivotTable";
import AggregationLinkingProgressPivotTable from "../screens/admin/analytics/aggregation/AggregationLinkingProgressPivotTable";
import AggregationAnalyticsDashboard from "../screens/admin/analytics/aggregation/AggregationAnalyticsDashboard";
import AggregationGroupingProgressPivotTable from "../screens/admin/analytics/aggregation/AggregationGroupingProgressPivotTable";

const AnalyticsRoutes = () => {
  return (
    <Routes>
      <Route path="/garment/:id" element={<GarmentDashboard />} />
      <Route path="/hours-worked" element={<HoursWorkedPivotTable />} />
      <Route path="/aggregation" element={<AggregationAnalyticsDashboard />} />
      <Route path="/aggregation/linking-progress" element={<AggregationLinkingProgressPivotTable fillPage={true}/>} />
      <Route path="/aggregation/grouping-progress" element={<AggregationGroupingProgressPivotTable fillPage={true}/>} />
      <Route path="/aggregation/user-speed" element={<></>} />
      <Route path="/cataloguing" element={<></>} />
      <Route path="/cataloguing/grouping-progress" element={<></>} />
      <Route path="/cataloguing/user-speed" element={<></>} />
    </Routes>
  );
};

export default AnalyticsRoutes;
