import React, { useEffect, useState } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Typography, useTheme } from "@mui/material";
import { getCurrentTimestamp } from "../../../utils/dateUtils";
import ClickableImage from "./Images/ClickableImage";

function SingleSelectGarmentWindow({
  selectedGarment,
  setSelectedGarment,
  disabled = false,
  additionalComponents = [],
  garments,
  imageIndex = 1,
}) {
  const { palette, typography } = useTheme();

  return (
    <Grid container>
      {garments.map((garment, index) => {
        const isSelected = selectedGarment.garment_id === garment.garment_id;
        return (
          <Grid
            item
            xs={2}
            key={garment.garment_id}
            onClick={(e) => {
              setSelectedGarment({ ...garment, index: index + 1 });
            }}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              backgroundColor: palette.primary[200],
              borderRadius: 2,
              p: 2,
              border: isSelected
                ? "3px solid green"
                : `1px solid ${palette.primary[300]}`,
              maxHeight: "200px",
              overflow: "hidden",
              cursor: "pointer",
            }}
          >
            <ClickableImage
              image={`https://scraped-garment-images.s3.eu-west-2.amazonaws.com/${garment.uuid_public_id}-${imageIndex}.jpg`}
            />
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: 100,
                height: 20,
                ml: 1,
              }}
            >
              <Typography variant="body_sm">{garment.score}</Typography>
            </Box>
          </Grid>
        );
      })}
      {additionalComponents.map((component) => {
        return component;
      })}
    </Grid>
  );
}
export default SingleSelectGarmentWindow;
