import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  useTheme,
  Modal,
  CircularProgress,
  Button,
  Checkbox,
} from "@mui/material";
import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
import TableRenderers from "react-pivottable/TableRenderers";
import Plot from "react-plotly.js";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import dayjs from "dayjs";
import { getHeaders } from "../../../../utils/apiUtils";
import { BASE_URL } from "../../../../config/apiConfig";
import useSize from "../../../../hooks/useSize";
const PlotlyRenderers = createPlotlyRenderers(Plot);

const AggregationGroupingProgressPivotTable = forwardRef(({fillPage=false, sx={}, width=null, height=null}, ref) => {
  const [aggregationGroupingPivotData, setAggregationGroupingPivotData] = useState([]);
  const [pivotState, setPivotState] = useState({});
  const { palette, typography } = useTheme();
  const [date, setDate] = React.useState([dayjs().subtract(14, "day"), dayjs()]);
  const [dataLoading, setDataLoading] = useState(false);
  const windowSize = useSize();
  const chartWidth = fillPage ? windowSize[0] : (width ?? 0.5*windowSize[0])
  const chartHeight = fillPage ? 0.3*windowSize[1] : (height ?? 0.25*windowSize[1])

  const refreshData = async () => {
    setAggregationGroupingPivotData([]);
    setDataLoading(true);
    const aggregationGroupingProgressData = await fetchData()
    
    //https://github.com/plotly/react-pivottable/blob/master/examples/tips.js
    //https://github.com/plotly/react-pivottable/blob/master/examples/App.jsx
    //https://react-pivottable.js.org/ [Line Chart]
    const headers = ['metric','count','date']
    const metricsGarmLevel = ['total_garms_available_for_cataloguing', 'total_catalogued_garms', 'total_catalogued_dataset_garms']
    const metricsGroupLevel = ['catalogue_length', 'total_groups_available_for_cataloguing', 'total_groups_catalogued']
    const dataGarmLevel = [headers]
    const dataGroupLevel = [headers]
    const metricObjectsGroupLevel = aggregationGroupingProgressData.forEach(dataObj => {
      const array = []
      metricsGroupLevel.forEach(metric => {
        const obj = {
          date: dataObj['date'],
          metric: metric,
          count: dataObj[metric]
        }
        // array in same format as headers
        const arr = [metric, dataObj[metric], dataObj['date']]
        array.push(arr)
      })
      dataGroupLevel.push(array)
    })
    console.log(`dataGroupLevel: ${JSON.stringify(dataGroupLevel)}`)
    setAggregationGroupingPivotData(dataGroupLevel);
  };

  useImperativeHandle(ref, () => {
    return {
      refreshData
    }
  })

  useEffect(() => {
    const pivotState = {
      data: aggregationGroupingPivotData,
      rows: ['metric'],
      cols: ['date'],
      aggregatorName: 'Sum over Sum',
      vals: ['count', 'metric'],
      rendererName: 'Line Chart',
      //sorters: {
      //    Meal: sortAs(['Lunch', 'Dinner']),
      //    'Day of Week': sortAs([
      //        'Thursday',
      //        'Friday',
      //        'Saturday',
      //        'Sunday',
      //    ]),
      //},
      plotlyOptions: {width: chartWidth, height: chartHeight}
      //plotlyConfig: {},
      //tableOptions: {
      //    clickCallback: function(e, value, filters, pivotData) {
      //        var names = [];
      //        pivotData.forEachMatchingRecord(filters, function(
      //            record
      //        ) {
      //            names.push(record.Meal);
      //        });
      //        alert(names.join('\n'));
      //    },
      //},
    }

    setPivotState(pivotState)
  }, [aggregationGroupingPivotData])

  const fetchData = async () => {
    setDataLoading(true);
    try {
      let headers = await getHeaders();
      const response = await axios.post(
        `${BASE_URL}tools/analytics/pivots/aggregation/grouping-progress`,
        {
          datasetId: 12,
          brandId: 5063,
          startDate: date[0].toISOString(),
          endDate: date[1].toISOString(),
        },
        headers
      );
      const newData = response.data.data;
      setDataLoading(false);
      return newData;
    } catch (error) {
      setDataLoading(false);
      console.error(`Error fetching proposals for Aggregation Grouping Progress:`, error);
      return []; // Return whatever was fetched before the error
    }
  };

  useEffect(() => {
    refreshData()
  }, [date])

  return (
    <>
      <Modal
        open={dataLoading}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Modal>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          overflow: "auto",
          p: 1,
          flexDirection: "column",
        }}
      >
        <Typography variant={fillPage ? "h2" : "h4"} sx={{
          p: 1, pt: 0
        }} color="primary.800">
          Aggregation Grouping Progress Pivot Table
        </Typography>
        <Box
          sx={{
            p: 1,
            border: `1px solid ${palette.primary[200]}`,
            borderRadius: 1,
            flexDirection: "row",
            display: "flex",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
              localeText={{ start: "Start", end: "End" }}
              onChange={(newValue) => setDate(newValue)}
              value={date}
            />
          </LocalizationProvider>

          {!ref && 
          <Box
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button variant="contained" onClick={refreshData}>
              Refresh
            </Button>
          </Box>}
          
        </Box>

        <Box
          sx={{
            mb: 1,
            p: 1,
            border: `1px solid ${palette.primary[200]}`,
            borderRadius: 1,
            ...sx
          }}
        >
          <PivotTableUI
            onChange={setAggregationGroupingPivotData}
            {...pivotState}
            renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
          />
        </Box>
      </Box>
    </>
  );
})

export default AggregationGroupingProgressPivotTable;
