import React, { useState, useRef } from "react";
import {
  Box,
  Modal,
  Grid,
  IconButton,
  Select,
  MenuItem,
  useTheme,
} from "@mui/material";

function ZoomImageModal({ openModal, handleClose, image, clickedImage, sx }) {
  const imageRef = useRef(null);

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    imageRef.current.style.backgroundPosition = `${x}% ${y}%`;
  };

  return (
    <Modal open={openModal} onClose={handleClose} sx={sx}>
        <Box
            ref={imageRef}
            onClick={handleClose}
            sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundImage: clickedImage !== null ? `url(${image})` : `none`,
            backgroundRepeat: "no-repeat",
            width: "60vw",
            height: "80vh",
            backgroundSize: "200%",
            transition: "background-size 0.3s ease-in-out",
            "&:hover": {
                backgroundSize: "250%",
            },
            }}
            onMouseMove={handleMouseMove}
        />
    </Modal>
  );
}

export default ZoomImageModal;
